import { SliderWithText } from '/features/pageOnly/SliderWithText'
import { Image } from '/features/buildingBlocks/Image'

import styles from './SliderServices.css'

export function SliderServices({ title, description, items }) {
  return (
    <div data-style-context='white' className={styles.component}>
      <SliderWithText interactionTitle='sectors-slider' {...{ title, description }}>
        {items.map(({ title, illustration, description }, i) => (
          <Card key={i} {...{ title, illustration, description }} />
        ))}
      </SliderWithText>
    </div>
  )
}

function Card({ title, illustration, description }) {
  return (
    <div className={cx(styles.componentCard, styles.component_rootCard)}>
      <h3 className={styles.title}>{title}</h3>
      {illustration?.asset && <Image image={illustration} layoutClassName={styles.imageLayout} />}
      <p className={styles.description}>{description}</p>
    </div>
  )
}
